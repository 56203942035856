<template>
  <Header />

  <div class="login">
    <h1>
      <i class="fas fa-arrow-left icon-back" @click="goBack"></i>
      {{ $t('pageLogin.pageTitle') }}
    </h1>

    <!-- Mockup image -->
    <img src="@/assets/images/ewallet_06.png" alt="Mockup d'interface de connexion" class="mockup-image">

    <div class="language-selector">
      <!-- Bouton pour afficher la langue sélectionnée -->
      <div class="dropdown-button" @click="toggleDropdown">
        <img :src="selectedLanguage.flag" :alt="selectedLanguage.name" class="flag-icon" />
        <span>{{ selectedLanguage.name }}</span>
      </div>

      <!-- Liste déroulante personnalisée -->
      <div v-if="dropdownOpen" class="dropdown-list">
        <div 
          v-for="lang in languages" 
          :key="lang.code" 
          class="dropdown-item" 
          @click="selectLanguage(lang)"
        >
          <img :src="lang.flag" :alt="lang.name" class="flag-icon" />
        </div>
      </div>
    </div>

    <!-- Formulaire de connexion -->
    <form @submit.prevent="handleSubmit" novalidate>
      <div class="form-group">
        <label for="username">
          <i class="fas fa-user icon-bg"></i>
          {{ $t('pageLogin.phoneOrEmail') }}
        </label>
        <input type="text" id="username" v-model="form.username" @input="handleUsernameInput" :placeholder="$t('pageLogin.phoneOrEmail')" required >
        <!-- Affichage du message d'erreur pour username -->
        <span class="error-message" v-if="usernameError">{{ usernameError }}</span>
      </div>

      <div class="form-group">
          <label for="password">
            <i class="fas fa-lock icon-bg"></i> {{ $t('pageLogin.password') }}
          </label>
          <div class="password-container">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              v-model="form.password"
              @input="handlePasswordInput"
              :placeholder="$t('pageLogin.password')"
              required
            />
            <i
              :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
              @click="togglePasswordVisibility"
              class="toggle-password-visibility"
            ></i>
          </div>
          <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
      </div>

      <div class="form-actions">
        <div class="stay-logged-in">
          <label>
            <input type="checkbox" v-model="keepLoggedIn">
            {{ $t('pageLogin.stayConnected') }}
          </label>
        </div>

        <div class="forgot-password">
          <router-link to="/forgot-password">{{ $t('pageLogin.forgotPassword') }}</router-link>
        </div>
      </div>

      <button type="submit" class="btn-submit" :disabled="loading">
        <span v-if="loading">
          {{ $t('pageLogin.connecting') }} <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-else>
          {{ $t('pageLogin.connect') }} <i class="fas fa-sign-in-alt"></i>
        </span>
      </button>

    </form>

    <!-- Lien de création de compte -->
    <div class="create-account">
      {{ $t('pageLogin.noAccount') }} <router-link to="/register-form" class="create-account-link">{{ $t('pageLogin.createAccount') }}</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../shared/HeaderPage.vue';
import { SERVER_IP } from '../../config';

export default {
  components: {
    Header
  },

  name: 'LoginPage',
  data() {
    return {
      form: {
        username: '',
        password: '',
        keepLoggedIn: false,
        loading: false,
      },
      usernameError: '', // Variable pour le message d'erreur du username
      passwordError: '', // Variable pour le message d'erreur du password
      showPassword: false,

      dropdownOpen: false, // Contrôle l'affichage de la liste déroulante
      selectedLanguage: { code: "fr", name: "Français", flag: "https://flagcdn.com/w40/fr.png" },
      languages: [
        { code: "fr", name: "Français", flag: "https://flagcdn.com/w40/fr.png" },
        { code: "ht", name: "Kreyòl", flag: "https://flagcdn.com/w40/ht.png" },
        { code: "en", name: "English", flag: "https://flagcdn.com/w40/us.png" },
      ],
    };
  },
  methods: {

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectLanguage(lang) {
      this.selectedLanguage = lang;
      this.$i18n.locale = this.selectedLanguage.code;
      this.dropdownOpen = false; // Ferme la liste après sélection
    },


    async handleSubmit() {

      this.loading = true; // Début du chargement
      
      // Valider le champ username
      if (!this.validateUsername()) {
        return; // Arrête la soumission si la validation échoue
      }
      // Valider le champ password
      if (!this.validatePassword()) {
        return; // Arrête la soumission si la validation échoue
      }

      this.login();
 
    },

    async login() {
      try {
        this.loading = true;
        const url = `${SERVER_IP}/login`;
        const response = await axios.post(url, {
          username: this.form.username,
          password: this.form.password
        });
        this.loading = false;

        // Gérer la réponse en fonction du retour du serveur
        const data = response.data;
        if (data.token) {
          localStorage.setItem('token', data.token);
        }
        if (data.tokenUser) {
          localStorage.setItem('tokenUser', data.tokenUser);
        }
        if (response.data.message === 'Connexion réussie') {
          this.$router.push('/home'); // Rediriger vers la page d'accueil
        }
        else if (response.data.message === 'user_pin') {
          this.$router.push('/create-pin');
        }
      } catch (error) {
        this.loading = false;
        if (error.response) {
          if (error.response.data.error === 'no_account') {
            this.usernameError = this.$t('pageLogin.noAccountFound');
          } else if (error.response.data.error === 'invalid_password') {
            this.passwordError = this.$t('pageLogin.invalidPassword');
          } else if (error.response.data.error === 'inactive_account') {
            this.usernameError = this.$t('pageLogin.accountInactive');
          }
        } else {
          console.error('Erreur de connexion:', error);
        }
      }
    },

    validateUsername() {
      // Vérifier le champ username
      const username = this.form.username.trim(); // Supprimer les espaces avant et après
      
      // Vérifier si le champ est vide
      if (!username) {
        this.usernameError = this.$t('pageLogin.enterPhone');
        this.loading = false; 
        return false; // Validation échoue
      }

      // Vérifier le format email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(username)) {
        this.usernameError = ''; // Réinitialiser le message d'erreur
        return true; // Validation réussie pour email
      }

      // Vérifier le format numéro de téléphone
      const phoneRegex = /^509(?:31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;
  
      if (/^\d+$/.test(username)) {
        if (username.length === 8 && phoneRegex.test('509'+username)) {
          this.usernameError = ''; // Réinitialiser le message d'erreur
          return true; // Validation réussie pour numéro à 8 chiffres
        } else if (username.length === 11 && phoneRegex.test(username)) {
          this.usernameError = ''; // Réinitialiser le message d'erreur
          return true; // Validation réussie pour numéro à 11 chiffres avec préfixe 509 et code valide
        }
      }

      // Si aucun des formats n'est valide
      this.usernameError = this.$t('pageLogin.enterValidPhone');
      this.loading = false; 
      return false; // Validation échoue
      
    },

    validatePassword() {
      // Vérifier le champ password
      const password = this.form.password.trim(); // Supprimer les espaces avant et après
      
      // Vérifier si le champ est vide
      if (!password) {
        this.passwordError = this.$t('pageLogin.enterPassword');
        this.loading = false; 
        return false; // Validation échoue
      }
      // Si toutes les validations passent
      this.passwordError = ''; // Réinitialiser le message d'erreur
      return true; // Validation réussie
    },


    handleUsernameInput() {
      this.usernameError = ''; // Réinitialiser le message d'erreur
    },
    handlePasswordInput() {
      this.passwordError = ''; // Réinitialiser le message d'erreur
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  
    goBack() {
      this.$router.go(-1); // Retourne à la page précédente
    },
  },
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.login {
  max-width: 400px;
  margin: auto;
  padding: 0px;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;;
}

.mockup-image {
  max-width: 200px;
  margin: 20px auto;
  display: block;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group i{
  margin-right: 10px;
}

.form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input {
  width: 95%;
  padding: 8px;
  font-size: 1em;
  border: none;
  border-bottom: 2px solid white;
  background-color: transparent;
  transition: border-color 0.3s;
  color: #FFFFFF;
}

.form-group input:focus {
  border-color: #016A40;
  outline: none;
}

.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
}

.forgot-password {
  text-align: right;
}

.forgot-password a {
  color: #178ff5;
  text-decoration: none;
}

.forgot-password a:hover {
  color: #106bb4;
}

.btn-submit {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.btn-submit:hover {
  background-color: #005934;
}

.back-icon {
  margin-right: 10px;
  cursor: pointer;
}

.create-account {
  margin-top: 20px;
  color: #ffffff;
}

.create-account a {
  color: #178ff5;
  text-decoration: none;
}

.create-account a:hover {
  color: #106bb4;
}

.stay-logged-in {
  display: flex;
  align-items: center;
}

.stay-logged-in label {
  margin-bottom: 0;
  margin-left: 5px;
}

.stay-logged-in input {
  margin-right: 5px;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}
.icon-back {
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    margin-right: 50px;
    color: black;
    font-size: 0.5em;
    cursor: pointer;
  }

.password-container {
  position: relative;
  width: 100%;
}
.toggle-password-visibility {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(105, 105, 105);
}
</style>

<style scoped>
.language-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligne les éléments à gauche */
  position: relative;
  color: #000;
  margin-bottom: 5%;
  margin-top: -5%;
  left: 0;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligner à gauche */
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  /* width: 100%; Prendre toute la largeur disponible */
  text-align: left;
}

.dropdown-button:hover {
  background-color: #f0f0f0;
  /* color: red; */
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0; /* Assure que la liste commence bien à gauche */
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* width: 100%; Assure que la liste reste bien alignée */
}

.dropdown-item {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligner à gauche */
  padding: 4px;
  cursor: pointer;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.flag-icon {
  width: 20px;
  height: 15px;
  margin-right: 8px; /* Espacement entre le drapeau et le texte */
}
</style>
