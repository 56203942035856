<template>
  <div v-if="showModal" class="modal">
    <!-- Modal Header -->
    <div class="modal-header">
      <h2>Statistiques de l'événement : {{ eventName }}</h2>
      <button @click="closeModal" class="close-btn">&times;</button>
    </div>

    <!-- Modal Body -->
    <div class="modal-body">
      <!-- Résumé Général -->
      <div class="summary-section">
        <div class="card">
          🧑‍🤝‍🧑 <strong>{{ totalParticipants }}</strong> Participants
        </div>
        <div class="card">
          🎫 <strong>{{ totalTicketsSold }}</strong> Tickets vendus
        </div>
        <div class="card">
          💬 <strong>{{ engagementRate }}%</strong> Engagement
        </div>
        <div class="card" :class="{ alert: totalReports > 0 }">
          🚨 <strong>{{ totalReports }}</strong> Signalements
        </div>
      </div>

      <!-- Engagement des Participants -->
      <div class="engagement-section">
        <h3>Engagement des participants</h3>
        <table class="engagement-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Total</th>
              <th>Tendance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Likes</td>
              <td>{{ likes }}</td>
              <td>{{ likesTrend }}</td>
            </tr>
            <tr>
              <td>Commentaires</td>
              <td>{{ comments }}</td>
              <td>{{ commentsTrend }}</td>
            </tr>
          </tbody>
        </table>
        <canvas ref="engagementChart"></canvas>
      </div>

      <!-- Ventes de Tickets -->
      <div class="sales-section">
        <h3>Ventes de tickets</h3>
        <p>Revenus générés : <strong>{{ revenue }} {{ currency }}</strong></p>
        <canvas ref="ticketSalesChart"></canvas>
      </div>

      <!-- Signalements -->
      <div class="reports-section">
        <h3>Signalements</h3>
        <ul class="report-list">
          <li v-for="report in reports" :key="report.id">
            {{ report.type }} - {{ report.status }} - {{ report.date }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer">
      <button @click="downloadStats" class="download-btn">Télécharger toutes les statistiques</button>
      <button @click="closeModal" class="close-btn">Fermer</button>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    eventId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      eventName: "Événement Exemple",
      totalParticipants: 120,
      totalTicketsSold: 85,
      engagementRate: 75,
      totalReports: 3,
      likes: 200,
      comments: 50,
      likesTrend: "⬆︎",
      commentsTrend: "⬇︎",
      revenue: 4500,
      currency: "USD",
      reports: [
        { id: 1, type: "Spam", status: "En cours", date: "2025-01-01" },
        { id: 2, type: "Contenu offensant", status: "Résolu", date: "2025-01-02" },
      ],
      engagementChartInstance: null,
      ticketSalesChartInstance: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    downloadStats() {
      alert("Statistiques téléchargées !");
    },
    renderEngagementChart() {
      const ctx = this.$refs.engagementChart?.getContext("2d");
      if (!ctx) return;

      this.engagementChartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: ["Jour 1", "Jour 2", "Jour 3", "Jour 4", "Jour 5"],
          datasets: [
            {
              label: "Likes",
              data: [10, 20, 30, 40, 50],
              borderColor: "blue",
              fill: false,
            },
            {
              label: "Commentaires",
              data: [5, 10, 15, 10, 5],
              borderColor: "green",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: { display: true },
          },
        },
      });
    },
    renderTicketSalesChart() {
      const ctx = this.$refs.ticketSalesChart?.getContext("2d");
      if (!ctx) return;

      this.ticketSalesChartInstance = new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["Standard", "VIP", "Premium"],
          datasets: [
            {
              data: [50, 25, 10],
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: { display: true },
          },
        },
      });
    },
    destroyCharts() {
      if (this.engagementChartInstance) {
        this.engagementChartInstance.destroy();
        this.engagementChartInstance = null;
      }
      if (this.ticketSalesChartInstance) {
        this.ticketSalesChartInstance.destroy();
        this.ticketSalesChartInstance = null;
      }
    },
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.renderEngagementChart();
          this.renderTicketSalesChart();
        });
      } else {
        this.destroyCharts();
      }
    },
  },
};
</script>

<style scoped>
.modal {
  width: 80%;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  max-height: 90vh;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.summary-section .card {
  display: inline-block;
  width: 22%;
  padding: 16px;
  background: #f8f9fa;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 8px;
}
.card.alert {
  background-color: #ffcccc;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
}
</style>
