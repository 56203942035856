<template>
  <Header />
  <PageHeader title="Centre des Événements" :fontSize="'1.70rem'" :goBack="customGoBack" />

  <div class="dashboard-events">
    <div class="btn-large-container">
        <button class="btn-add-saving" @click="createEvents">
            Créer un événement <i class="fas fa-plus-circle"></i>
        </button>
    </div>

    <div class="search-container">
      <!-- Input de recherche -->
      <div class="search-input">
          <input 
              type="text" 
              v-model="searchQuery" 
              placeholder="Titre de l'événement" 
              @keyup.enter="searchEvents"
          />
          <!-- Icône de recherche à l'intérieur de l'input -->
          <button @click="searchEvents" class="search-icon">
              <i class="fas fa-search"></i>
          </button>
      </div>

      <!-- Bouton pour les filtres avec icône uniquement -->
      <button class="filter-btn"  @click="openModalFilters">
          <i class="fas fa-sliders-h"></i>
      </button>
    </div>

    <div class="loading-fetch" v-if="loadingFetch">
        <i class="fas fa-spinner fa-spin"></i>
    </div>

    <div v-else>
      <div v-if="events.length">
        <div v-for="(event, index) in events" 
          :key="index" 
          class="event-preview"
        >
            <!-- Catégorie -->
            <div class="event-category">
              <i class="fas fa-cog icon-left" @click="setViewConfigModal(event)"></i>
              <span class="category-text">{{ event.category }}</span>
              <i 
                :class="event.favorite ? 'fas fa-heart icon-right' : 'far fa-heart icon-right'" 
                @click="openFeedbackModal(event.id)">
              </i>
            </div>


            <!-- Image -->
            <div class="event-image-container">
              <img 
                :src="mediaUrls[event.id] || 'placeholder.jpg'"  
                alt="Image de l'événement" 
                class="preview-image" 
              />
            </div>


            <!-- Informations principales -->
            <div class="event-details-container">
              <div class="event-details">
                <h2 class="event-title">{{ event.title }}</h2>
                <p class="event-organizer">par {{ event.organizer_id }}</p>
              </div>
              <button class="share-button" @click="shareEvent(event)">
                <i class="fas fa-share-alt"></i>
                <span>Partager</span>
              </button>
            </div>


            <!-- Sous-div des informations secondaires -->
            <div class="event-secondary-details">
              <!-- Sous-div gauche -->
              <div class="event-location-time">
                <p v-if="event.event_type === 'physical'">
                  <i class="fas fa-map-marker-alt"></i> {{ event.address }}
                </p>
                <p v-else>
                  <i class="fas fa-globe"></i> {{ event.url }}
                </p>
                <p><i class="fas fa-calendar-alt"></i> {{ formatShortDateText(event.start_date) }}</p>
                <p><i class="fas fa-clock"></i> {{ formatTimeText(event.start_date) }}</p>
              </div>

              <!-- Sous-div droite -->
              <div class="event-stats">
                <p><i class="fas fa-star"></i> {{event.rating}}/5</p>
                <p><i class="fas fa-users"></i> {{event.purchases}} participants</p>
              </div>
            </div>

            <button @click="eventSelectedID = event.id; showModalTicketing = true" class="btn-submit">
              <i class="fas fa-money-check-alt"></i> Acheter des tickets
            </button>
        </div>

        <!-- Bloc de pagination -->
        <div class="pagination-container">
            <div class="pagination" v-if="totalPages > 1">
                <button @click="prevPage" :disabled="currentPage === 1">
                    <i class="fa-solid fa-angles-left"></i>
                </button>
                <span> {{ currentPage }} / {{ totalPages }} </span>
                <button @click="nextPage" :disabled="currentPage === totalPages">
                    <i class="fa-solid fa-angles-right"></i>
                </button>
            </div>
        </div>


      </div>
      <div v-else class="no-results-message">
          Aucun événement ne correspond à votre recherche actuelle. <br><br>
          Veuillez vérifier vos filtres ou créer un nouvel événement.
      </div>
    </div>
    
    
    <ModalFiltersEvents 
      :isOpen="showModalFilters"
      @apply-filters="handleApplyFilters" 
      @close="closeModalFilters" 
    />

    <ModalTicketing 
      :isModalVisible="showModalTicketing"
      :eventID="eventSelectedID" 
      @close="closeModalTicketing" 
    />
    <ModalTickettingSuccess 
      :visible="isModalSuccessTicketsVisible" 
      @close="isModalSuccessTicketsVisible = false"
    />

    <!-- EventConfigModal -->
    <EventConfigModal 
      :visible="showModalConfig" 
      :isOrganizer="isConfigOrganizer" 
      @close="showModalConfig = false"
      @selectOption="handleOptionSelectionConfig"
    />

    <!-- Modal de suppression -->
    <DeleteEventModal
      :visible="isDeleteModalVisible"
      :event="selectedEvent"
      @close="handleDeleteModalClose"
      @delete-event="handleEventDeletion"
    />


    <!-- Modal de suivi d'événement -->
    <ModalEventFeedback
      v-if="isFeedbackModalVisible"
      :visible="isFeedbackModalVisible"
      :eventId="selectedEventId"
      :userId="userId"
      @close="closeFeedbackModal"
    />

    <!-- Modal de scommentaires -->
    <CommentsModal
      :visible="showModalComments"
      :userId="userId"
      :eventId="selectedEventId"
      @close="showModalComments = false"
    />

    <!-- Modal des complaints ComplaintModal -->
    <ComplaintModal
      :visible="showModalComplaint"
      :userId="userId"
      :eventId="selectedEventId"
      :eventDate="selectedEventDate"
      @close="showModalComplaint = false"
    />

    <!-- Modal pour les tickets d'un utilisateur -->
    <ModalTicketsUser
      :isVisible="showModalTicketsUser"
      :userId="userId"
      :eventId="selectedEventId"
      @close="showModalTicketsUser = false"
    />

    <!-- Modal pour les Stats-->
    <ModalStats
      v-if="openStatsModal"
      :eventId="selectedEventId"
      @close="closeStatsModal"
    />

    <!-- LoginRequiredModal -->
    <LoginRequiredModal 
      :visible="showModalRequireLogin" 
      @close="showModalRequireLogin = false"
    />


    
  </div>

  <Footer :key="footerKey" />

</template>
  
<script>
    import Header from '../shared/HeaderPage.vue';
    import Footer from '../shared/FooterPage.vue';
    import PageHeader from '../shared/PageHeader.vue';
    import axios from 'axios';
    import { SERVER_IP } from '../../config';
    import { MEDIA_SERVER_URL } from '../../config';

    import { checkAuth, formatShortDateText, formatTimeText } from '@/utils/validationUtils';

    import ModalTicketing from './ModalTicketing.vue';
    import ModalTickettingSuccess from './ModalTickettingSuccess.vue';
    import ModalFiltersEvents from './ModalFiltersEvents.vue';
    import ModalEventFeedback from './ModalEventFeedback.vue';
    import EventConfigModal from './EventConfigModal.vue';
    import DeleteEventModal from "./DeleteEventModal.vue"; // Chemin du moda
    import CommentsModal from './CommentsModal.vue';
    import ComplaintModal from './ComplaintModal.vue';
    import ModalTicketsUser from './ModalTicketsUser.vue';

    import ModalStats from './ModalStats.vue';    
    import LoginRequiredModal from '@/components/modals/LoginRequiredModal.vue';


    export default {
        name: "DashboardEvents",
        components: {
            Header,
            PageHeader,
            Footer,

            ModalTicketing,
            ModalTickettingSuccess,
            ModalFiltersEvents,
            ModalEventFeedback,
            EventConfigModal,
            DeleteEventModal,
            CommentsModal,
            ComplaintModal,
            ModalTicketsUser,

            ModalStats,

            LoginRequiredModal,
        },

        props: ['customLink', 'eventId'],  // Paramètres passés par la route

        data() {
          const today = new Date().toISOString().slice(0, 10); // Date du jour au format 'YYYY-MM-DD'
            return {
                searchQuery: '',
                loadingFetch: true,
                currentPage: 1,     // Page courante
                visibleEventsCount: 1,   // Nombre d'éléments par page
                totalPages: 1,
                events: [], // Liste complète des événements
                mediaUrls: {}, // Dictionnaire pour stocker les URLs des images
                isLoading: true, // Indicateur de chargement

                hasCustomLink: false,
                hasEventId: false,

                showModalFilters: false,
                selectedFilters: {
                  nextEvents: true,
                  myEvents: false,
                  category: '0',
                  tickets: false,
                  startDate: today, // Date de début initialisée à aujourd'hui
                  endDate: today,   // Date de fin initialisée à aujourd'hui
                  selectedOrder: 3, 
                },

                showModalTicketing: false,
                eventSelectedID: null,
                isModalSuccessTicketsVisible: false,

                // EventConfigModal
                showModalConfig: false,
                isConfigOrganizer: true,

                // ModalEventFeedback
                isFeedbackModalVisible: false, // Contrôle de la visibilité du modal
                selectedEventId: null,         // ID de l'événement sélectionné
                userId: 0, 
                userIdFeedback: 0,

                // LoginRequiredModal
                showModalRequireLogin: false,

                //Modal de supression
                isDeleteModalVisible: false,
                selectedEvent: null,

                // Modal des comments
                showModalComments: false,
                selectedEventDate: null,

                // Modal pour les tickes des utilisateurs
                showModalTicketsUser: false,

                // Modal pour les stats
                isStatsModalOpen: false,

                // Modal des Complaintes
                showModalComplaint: false,
            };
        },

        created() {
          this.userId = checkAuth(false);
        },

        
        mounted() {     
          if (this.customLink) {
            this.hasCustomLink = !!this.customLink; // Vérifie si `customLink` est non nul
            console.log("customlink: " +this.customLink);
          } else if (this.eventId) {
            this.hasEventId = !!this.eventId;
            console.log("eventID: " +this.eventId);
          }

          this.initiateFilters();
          this.fetchEvents(); // Appel à l'API pour récupérer les économies une fois le composant monté
        },

        beforeUnmount() {
          if (localStorage.getItem('savedFilters')) {
              localStorage.removeItem('savedFilters');
          }
        },

        watch: {
          // Surveille les changements dans la liste des événements
          events: {
            handler() {
              this.loadImages(); // Recharge les URLs dès que les événements sont disponibles
            },
            deep: true, // Si `events` est un tableau complexe, ce flag est nécessaire
          },
        },

        methods: {
          formatShortDateText(date) {
              return formatShortDateText(date);
          },
          formatTimeText(date) {
            return formatTimeText(date);
          },

          createEvents() {
            if(!this.loadingFetch)
              this.$router.push('/create-events');
          },

          nextPage() {
              if (this.currentPage < this.totalPages) {
                  this.currentPage++;
                  this.fetchEvents();  // Charger les données de la nouvelle page
              }
          },
          prevPage() {
              if (this.currentPage > 1) {
                  this.currentPage--;
                  this.fetchEvents();  // Charger les données de la nouvelle page
              }
          },

          async getURLImage(url) {
            if (!url) {
              return 'placeholder.jpg'; // Si l'URL est vide
            }

            const fullUrl = `${MEDIA_SERVER_URL}events/${url}`;
            try {
              const response = await fetch(fullUrl, { method: 'HEAD' });
              if (response.ok) {
                return fullUrl; // Retourne le lien complet si le fichier existe
              }
              console.warn(`Fichier non trouvé: ${fullUrl}`);
            } catch (error) {
              console.error('Erreur lors de la vérification du fichier :', error);
            }

            // Retourne l'image par défaut si l'URL n'existe pas ou est inaccessible
            return 'placeholder.jpg';
          },

          async loadImages() {
            // Charge les URLs des images pour tous les événements
            const urls = {};
            for (const event of this.events) {
              urls[event.id] = await this.getURLImage(event.image_url);
            }
            this.mediaUrls = urls;
          },


          searchEvents (){
              this.currentPage = 1;
              this.fetchEvents();
          },

          async fetchEvents() {
            this.loadingFetch = true;
            if(this.hasCustomLink || this.hasEventId){
              this.getEvent();
              return true;
            }
            const url = `${SERVER_IP}/eventsManagement/getEvents`;
            this.events = [];

            try {
                const response = await axios.post(url, {
                    userId: this.userId,
                    searchQuery: this.searchQuery,
                    currentPage: this.currentPage, 
                    itemsPerPage: this.visibleEventsCount,

                  
                    nextEvents: this.selectedFilters.nextEvents,
                    myEvents: this.selectedFilters.myEvents,
                    category: this.selectedFilters.category,
                    tickets: this.selectedFilters.tickets,
                    startDate: this.selectedFilters.startDate, // Date de début initialisée à aujourd'hui
                    endDate: this.selectedFilters.endDate,   // Date de fin initialisée à aujourd'hui
                    selectedOrder: this.selectedFilters.selectedOrder,
                });

                if (response.status === 200 ) {
                    // On met à jour les données locales après réception de l'API
                    this.events = response.data.events;   // Tableau des économies
                    this.totalPages = response.data.totalPages;   // Total des pages
                }
                
            } catch (error) {
                console.log(error.response.data.message);
            } finally {
              this.loadingFetch = false;
            }
          },

          async getEvent() {
            this.loadingFetch = true;
            
            const url = `${SERVER_IP}/eventsManagement/getOneEvents`;
            this.events = [];

            try {
                const response = await axios.post(url, {
                    customLink: this.customLink,
                    eventId: this.eventId,
                    userId: this.userId
                });

                if (response.status === 200 ) {
                    // On met à jour les données locales après réception de l'API
                    this.events = response.data.events;   // Tableau des économies
                    this.totalPages = 1;   // Total des pages
                }
                
            } catch (error) {
                console.log(error.response.data.message);
            } finally {
              this.loadingFetch = false;
            }
          },

          shareEvent(event) {
            let link = "https://www.kobdirek.com/dashboard-events";

            if (event.custom_link) {
              link += `/${event.custom_link}`;
            } else {
              link += `/${event.id}`;
            }

            // Image associée à l'événement (URL)
            const imageURL = `${MEDIA_SERVER_URL}events/${event.image_url}` || ""; // Assurez-vous que `event.imageURL` contient l'URL de l'image

            // Vérifier si `navigator.share` est pris en charge
            if (navigator.share) {
              // Préparation du fichier image
              if (imageURL) {
                fetch(imageURL)
                  .then(res => res.blob())
                  .then(blob => {
                    const file = new File([blob], "event-image.jpg", { type: blob.type });

                    navigator
                      .share({
                        title: 'Kob Direk - Partagez cet événement',
                        text: `Découvrez cet événement sur Kob Direk : ${event.title}`,
                        url: link,
                        files: [file]
                      })
                      .then(() => console.log('Partage réussi avec l’image !'))
                      .catch(error => console.error('Erreur lors du partage :', error));
                  })
                  .catch(error => console.error('Erreur lors du téléchargement de l’image :', error));
              } else {
                navigator
                  .share({
                    title: 'Kob Direk - Partagez cet événement',
                    text: `Découvrez cet événement sur Kob Direk : ${event.title}`,
                    url: link
                  })
                  .then(() => console.log('Partage réussi !'))
                  .catch(error => console.error('Erreur lors du partage :', error));
              }
            } 
            else {
              // Partage manuel
              const message = encodeURIComponent(
                `Découvrez cet événement sur Kob Direk : ${event.title}. Cliquez ici : ${link}`
              );

              const whatsappLink = `https://wa.me/?text=${message}`;
              const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
              const twitterLink = `https://twitter.com/intent/tweet?text=${message}`;
              const emailLink = `mailto:?subject=Partage d'événement&body=${message}`;

              const options = `
                <div>
                  <a href="${whatsappLink}" target="_blank">Partager via WhatsApp</a><br>
                  <a href="${facebookLink}" target="_blank">Partager sur Facebook</a><br>
                  <a href="${twitterLink}" target="_blank">Partager sur Twitter</a><br>
                  <a href="${emailLink}" target="_blank">Partager par Email</a><br>
                  <button onclick="navigator.clipboard.writeText('${link}').then(() => alert('Lien copié dans le presse-papiers!'));">Copier le lien</button>
                </div>
              `;

              console.log(options); // Affiche le menu dans la console ou insérez-le dans votre UI
            }
          },

          closeModalTicketing(boughtValue) {
            this.isModalSuccessTicketsVisible = false;
            let bought = boughtValue;
            this.showModalTicketing = false;
            if(bought) {
              this.isModalSuccessTicketsVisible = true;
            }

          },

          openModalFilters() {
            if(!this.loadingFetch)
              this.showModalFilters = true;
          },
          closeModalFilters() {
            this.showModalFilters = false;
          },
          initiateFilters() {
              localStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
          },
          handleApplyFilters(filters) {
              this.selectedFilters = filters;
              this.currentPage = 1;
              this.fetchEvents();
          },

          // ModalEventFeedback
          openFeedbackModal(eventId) {
            if(this.userId === 0) {
              this.showModalRequireLogin = true;
              return;
            }
            this.selectedEventId = eventId;    // Enregistre l'ID de l'événement
            this.userIdFeedback = this.userId;
            this.isFeedbackModalVisible = true; // Rend le modal visible
          },
          closeFeedbackModal() {
            this.isFeedbackModalVisible = false; // Cache le modal
            this.selectedEventId = null;        // Réinitialise l'ID
          },

          // EventConfigModal
          setViewConfigModal(event) {
            if(this.userId === 0) {
              this.showModalRequireLogin = event.isOwner;
              return;
            }

            this.selectedEvent = event;
            this.selectedEventDate = this.selectedEvent.start_date;
            this.isConfigOrganizer = event.isOwner;
            this.selectedEventId = this.selectedEvent.id;
            this.showModalConfig = true;
          },

          handleOptionSelectionConfig(option) {
            // Ajoutez ici votre logique en fonction de l'option choisie
            switch (option) {
              case "deleteEvent":
                this.openDeleteModal();
                break;

              case "viewComments":
                this.showModalComments = true;
                break;

              case "reportProblem":
                this.showModalComplaint = true;
                break;

              case "viewTickets":
                this.showModalTicketsUser= true;
                break;

              case "shareEvent":
                this.shareEvent(this.selectedEvent);
                break;

              case "viewStats":
                alert(this.selectedEventId);
                this.isStatsModalOpen = true;
                break;

                
            }
          },


          // Modal de supression Event
          openDeleteModal() {
            this.showModalConfig = false;
            this.isDeleteModalVisible = true; // Affiche le modal
          },

          handleDeleteModalClose(deletionStatus) {
              this.isDeleteModalVisible = false; // Ferme le modal

              if (deletionStatus) {
                  // Si la suppression a eu lieu, retirer l'événement de la liste
                  const eventRemoved = this.events.some(
                      (event) => event.id === this.selectedEvent.id
                  );

                  if (eventRemoved) {
                      this.events = this.events.filter(
                          (event) => event.id !== this.selectedEvent.id
                      );

                      // Si la liste est vide après suppression et qu'il y a plusieurs pages
                      if (this.events.length === 0 && this.totalPages > 1) {
                          // Si on est sur la dernière page, revenir à la page précédente
                          if (this.currentPage === this.totalPages) {
                              this.currentPage -= 1;
                          }

                          // Recharger les événements pour la page actuelle
                          this.fetchEvents();
                      }
                  }

                  // Réinitialiser l'événement sélectionné
                  this.selectedEvent = null;
              }
          },

          openStatsModal() {
            this.showModalConfig = false;
            this.isStatsModalOpen = true;
          },

          closeStatsModal() {
            this.isStatsModalOpen = false;
            this.showModalConfig = true;
          }



         

        }
       
        
    };

</script>
  
<style scoped>
  .dashboard-events {
    max-width: 400px;
    margin: auto;
    padding: 0px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5.5%;
    font-size: 0.8em;
  }

  .btn-large-container {
    text-align: center;
    margin-top: 20px;
  }
  .btn-add-saving {
    background-color: #016A40; /* Fond vert */
    color: white; /* Texte blanc */
    border: none;
    border-radius: 5px; /* Coins moins circulaires */
    padding: 10px 20px; /* Ajustement du rembourrage */
    cursor: pointer;
    transition: background-color 0.3s; /* Transition pour l'animation */
    width: 100%;
  }

  /* SEARCH CONTAINER */
  /* Style du conteneur */
  .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  /* Style de l'input de recherche */
  .search-input {
    position: relative;
    flex-grow: 1;
    margin-right: 15px; /* Espace entre l'input et le bouton filters */
    max-width: 75%;
  }

  .search-input input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    background-color: white; /* Arrière-plan blanc */
    color: black; /* Texte noir */
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .search-input input::placeholder {
    color: lightgray; /* Placeholder gris clair */
  }

  /* Icône de recherche à l'intérieur de l'input */
  .search-input .search-icon {
    position: absolute;
    right: -15%;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: black; /* Couleur de l'icône de recherche */
    cursor: pointer;
    font-size: 18px;
  }

  /* Bouton de filtre */
  .filter-btn {
    padding: 10px;
    background-color: white; /* Arrière-plan blanc */
    color: black; /* Texte noir */
    border: none;
    border-radius: 5px;
    font-size: 16px; /* Taille de l'icône */
    cursor: pointer;

  }

  .filter-btn i {
    margin: 0;
  }

  .loading-fetch {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      font-size: 2.5rem;
      margin-top: 40%;
  }
  
</style>

<style scoped>
  .event-preview {
  background-color: #101010;
  color: white;
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.event-category {
  display: flex;
  align-items: center; /* Aligner les éléments verticalement */
  justify-content: space-between; /* Séparer les éléments aux extrémités */
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
}

.event-category .icon-left,
.event-category .icon-right {
  font-size: 1.5rem; /* Taille des icônes */
  cursor: pointer; /* Ajout d'un curseur pour indiquer que l'icône est cliquable */
}

.event-category .icon-left {
  margin-right: auto; /* Aligner l'icône à gauche */
}

.event-category .icon-right {
  margin-left: auto; /* Aligner l'icône à droite */
}

.event-category .category-text {
  flex-grow: 1; /* Occupe l'espace restant entre les icônes */
  text-align: center; /* Centre le texte */
}


.event-image-container {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.preview-image {
  max-width: 350; /* Limite maximale de largeur */
  max-height: 200px; /* Limite maximale de hauteur */
  width: auto; /* Ajuste automatiquement la largeur pour conserver le ratio */
  height: auto; /* Ajuste automatiquement la hauteur pour conserver le ratio */
  border-radius: 15px; /* Coins arrondis */
  object-fit: contain; /* Maintient tout l'image visible dans les limites */
  display: block; /* Aligne correctement l'image */
  margin: 0 auto; /* Centre l'image horizontalement */
}


.event-details-container {
  display: flex;
  align-items: center; /* Centre verticalement */
  justify-content: space-between; /* Espace entre les éléments */
  margin-top: 15px;
}

.event-details {
  text-align: left;
}

.event-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
}

.event-organizer {
  font-style: italic;
  color: #aaa;
  margin-top: 0px;
}

.share-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 70px;
  height: 70px;
  border: 1px solid #ddd;
  border-radius: 10px; /* Coins arrondis */
  background-color: #fff; /* Fond blanc */
  color: #000; /* Texte noir */
  font-size: 0.8rem; /* Taille du texte sous l'icône */
  cursor: pointer;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.share-button i {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.share-button:hover {
  background-color: #f0f0f0; /* Couleur d'arrière-plan au survol */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.event-secondary-details {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 15px;
  background-color: #1a1a1a;
  border-radius: 10px;
}

.event-location-time,
.event-stats {
  flex: 1;
  margin: 0 10px;
  text-align: left;
}



.event-location-time p,
.event-stats p {
  margin: 5px 0;
}

.event-location-time p i,
.event-stats p i {
  margin-right: 5px;
  color: #00b894;
}


.btn-submit {
  background-color: #016A40; /* Fond vert */
  color: white; /* Texte blanc */
  border: none;
  border-radius: 5px; /* Coins moins circulaires */
  padding: 10px 20px; /* Ajustement du rembourrage */
  cursor: pointer;
  transition: background-color 0.3s; /* Transition pour l'animation */
  width: auto;
  margin-top: 5%;
  margin-bottom: 2.5%;
}

.btn-submit:hover {
  background-color: #005934; /* Changement de couleur au survol */
}

.btn-submit:active {
  background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

/****************** NO RESULT FETCH ************************/
.no-results-message {
    display: flex;
    text-align: center;
    color: white; /* Texte en blanc */
    border-radius: 10px; /* Coins arrondis */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); /* Ombre portée */
    padding: 20px;
    transition: all 0.3s ease;
    margin-top: 25%;
}

/********************* PAGINATION **********************/
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20%;
}

.pagination {
    display: flex;
    align-items: center;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    margin: 0 10px;
    font-size: 12px;
    font-weight: bold;
}
</style>
  