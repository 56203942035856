// utils/translator.js
import { createI18n } from 'vue-i18n';

// Définir les messages de traduction
const messages = {
    locale: 'fr',
    fallbackLocale: 'fr',

    en: { 
        pageLogin: {
            pageTitle: 'Access Your Account',
            phoneOrEmail: 'Phone number or email address',
            password: 'Password',
            stayConnected: 'Keep me signed in',
            forgotPassword: 'Forgot password?',
            connecting: 'Please wait, signing in..',
            connect: 'Log in',
            createAccount: 'Sign up',
            noAccount: 'Don’t have an account?',
            noAccountFound: 'No account found for this phone number or email.',
            invalidPassword: 'Incorrect password.',
            accountInactive: 'Your account is not yet activated. Please check your email to activate it.',
            enterPhone: 'Please enter your phone number or email.',
            enterValidPhone: 'Please enter a valid email or phone number.',
            enterPassword: 'Please enter your password.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Forgot Password',
            phoneOrEmail: 'Phone number or email address',
            loading: 'Loading...',
            reset: 'Reset',
            accueil: 'Home page',
            linkSent: 'A reset link has been sent. Please check your email.',
            errorRegistered: 'An error occurred during the reset request.',
        },

        message: { 
            hello: 'Hello World',
            stayConnected: 'Stay connected',
            fullName: 'Full Name',
            email: 'Email',
            phoneNumber: 'Phone Number',
            address: 'Address',
            dateOfBirth: 'Date of Birth',
            gender: 'Gender',
            personalInfo: 'Personal Information',
            securityPrivacy: 'Security and Privacy',
            preferences: 'Preferences',
            activityHistory: 'Activity History',
            accountManagement: 'Account Management',
            supportAssistance: 'Support and Assistance',
        },
        pageTitle: { 
            profile: 'My Profile',
        },

        generalContent:{
            validateContactError_1: 'The Phone Number or Email field is required.',
            validateContactError_2: 'Please enter a valid email or phone number.',
            passwordPattern: 'The password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special symbol.',
        },

        pageBasicTransfert: {
            pageTitle: 'Initiating a Transfer',
            phoneOrEmail2: 'Beneficiary’s phone or email',
            phoneemail3: 'Enter the phone number or email',
            emptyfnb: 'The Phone Number or Email field is required.',
            wrongnuberemail: 'Please enter a valid email or phone number.',
            devise: 'Currency Selection',
            Mount: 'Amount',
            AmountInput: 'Enter the amount',
            withoutmount: 'Please check your amount',
            Boutton: 'Submit',
            subboutton: 'Processing...',
            wrongaccount: 'No associated accounts found.',
            Insufficient_funds: 'Your balance is insufficient for the amount plus the transfer fee.',
            no_pin: 'For security reasons, you must first create an authentication key before you can perform transactions.<br/><br/> This key will serve as a second factor of authentication to validate your financial operations.<br/><br/> Please configure your authentication key in your profile to proceed.',
            create_pin: 'Set up',
            name: 'Beneficiary',
            deviseT: 'Currency',
            fees: 'Transaction Fees',
            mountT: 'Total Amount',
            pin: 'Enter your PIN',
            Boutton2: 'Confirm',
            subboutton2: 'Transfer in progress',
            errorpin: 'Your PIN is incorrect.',
            pageTitle3: 'Transfer Complete',
            success: 'Your transaction has been successfully completed.',
            Boutton3: 'Home',
            infoMissed: 'Please fill in all the required fields.',
            inactifAccount: 'The account is inactive.',
            sameAccount: 'You cannot transfer to the same account.',
            unknownError: 'Unknown error. Please try again.',
            pinALpha: 'Please fill in all the fields only with numbers.'
        },

        pageBasicWithdraw: {
            title: "Withdraw Funds",
            agentTitle: "At an Agent",
            bankTitle: "To a Bank",
        
            phoneEmailAgent: "Agent's Phone or Email",
            enterPhoneEmail: "Enter email or phone number",
            currencyChoice: "Currency Selection",
            HTG: "Haitian Gourde (HTG)",
            USD: "US Dollar (USD)",
            amount: "Amount",
            enterAmount: "Enter the amount",
            submit: "Submit",
        
            bankChoice: "Select Bank",
            accountNumber: "Account Number",
            enterAccountNumber: "Enter the account number",
            accountName: "Account Name",
            enterAccountName: "Enter the account name",
        
            securityMessage: "For security reasons, you must first create an authentication key before making transactions.",
            securityMessage2: "This key will serve as a second authentication factor to validate your financial operations.",
            securityMessage3: "Please set up your authentication key in your profile to continue.",
            configure: "Set Up",
        
            agent: "Agent:",
            bank: "Bank:",
            currency: "Currency:",
            transactionFee: "Transaction Fee:",
            totalAmount: "Total Amount:",
        
            insertPin: "Enter your PIN",
            processing: "Processing withdrawal",
            validate: "Confirm",
        
            withdrawSuccess: "Your withdrawal has been successfully processed.",
            accueil: "Home",
        
            withdrawProcessing: "Your withdrawal request is being processed.",
            withdrawPending: "The provider still needs to validate the transaction.",
            followTransaction: "You can track the progress or cancel the request anytime in your ",
            followTransaction2: " or your",
            transactionHistoric: "transaction history",
            notifications: "notifications",
        
            required_fields: "Please fill in all required fields.",
            no_account_found: "No associated account found.",
            inactive_account: "The account is inactive.",
            same_account: "You cannot transfer to the same account.",
            insufficient_funds: "Your balance is insufficient to cover the amount and transfer fees.",
            unknown: "Unknown error. Please try again.",
            check_amount: "Please check your amount",
            server_communication: "Error communicating with the server.",
        
            pin_numeric: "Please fill in all fields with numbers.",
            invalid_pin: "Your PIN code is incorrect.",
        
            account_name_required: "Please enter an account name.",
            account_name_min_length: "Account name must be at least 3 characters long.",
            account_name_max_length: "Account name cannot exceed 30 characters.",
            account_name_invalid: "Account name can only contain letters, accents, spaces, hyphens, periods, and underscores.",
        
            account_number_required: "Please enter an account number.",
            account_number_length: "Account number must be exactly 10 digits long.",
            account_number_invalid: "Account number can only contain numbers, hyphens, and spaces."
        },

        pageBasicDeposit:{
            pageTitle: 'Deposit Money to Your Account',
            account: "Agent's Phone or Email",
            devise: "Select a Currency",
            amount: "Amount",
            underAmount: "Enter the amount",
            underAccount: "Enter the agent's email or phone number",
            nameHTG: "Gourd",
            nameUSD: "Dollar",
            button: "Submit",
            notPutAmount: "Please verify your amount",
            tooMuchAmount: "This agent cannot process this deposit for you",
            unknownNumberOrMail: "No associated accounts found",
            inactifAccount: 'The account is inactive.',
            unknownError: 'Unknown error. Please try again.',
            sameAccount: 'You cannot transfer to the same account.',
            infoMissed: 'Please fill in all the required fields.',

            noRecordPin: 'For security reasons, you must first create an authentication key before you can perform transactions.<br/><br/> This key will serve as a second factor of authentication to validate your financial operations.<br/><br/> Please set up your authentication key in your profile to continue.',
            configure: 'Set Up',

            name: "Agent",
            deviseT: "Currency",
            fees: "Transaction Fees",
            mountT: "Total Amount",
            enterPin: "Enter Your PIN",
            button2: "Confirm",
            subBoutton2: "Processing transaction...",
            badPin: 'Please fill in all fields with numbers.',
            errorPin: "Your PIN is incorrect",

            successDeposit: 'Your deposit has been successfully completed.',
            Button3: 'Home',
            waitingText: 'Your deposit request is being processed.\n\nThe provider must still validate the transaction.\n\nYou can track the progress or cancel the request at any time by viewing your <a href="/transaction-history">transaction history</a> or your <a href="/notifications">notifications</a>.',

        },
        pageConfirmWithdraw: {
            title: "Withdrawal Confirmation",
            client: "Client :",
            amount: "Amount :",
            currency: "Currency :",
            transaction_fee: "Transaction Fee :",
            total_amount: "Total Amount :",
            commission: "Commission :",
            insert_pin: "Insert your pin",
            error_invalid: "Your PIN code is incorrect",
            error_unknown: "Unknown error. Please try again.",
            error_fill_pin: "Please fill all fields with digits.",
            withdraw_success: "You have successfully confirmed the withdrawal.",
            home: "Home",
            withdraw_in_progress: "Confirmation in progress",
            validate: "Validate"
        },

        pageConfirmDeposit:{
            title: 'Deposit Confirmation',
        },
        pageTracking: {
            title: "Transaction Tracking",
            type: "Type",
            type_deposit: "Deposit",
            type_withdrawal: "Withdrawal",
            type_transfer: "Transfer",
            type_default: "Unknown Type",
            status_pending: "Pending",
            status_completed: "Success",
            status_failed: "Failed",
            status_default: "Unknown Status",
            agent: "Agent",
            bank: "Bank",
            account_number: "Account Number",
            account_name: "Account Name",
            amount: "Amount",
            currency: "Currency",
            transaction_fee: "Transaction Fee",
            total_amount: "Total Amount",
        
            cancel_button: "Cancel",
            pin_insert_title: "Enter your PIN",
            cancel_button_submit: "Confirm Cancellation",
            cancel_button_loading: "Cancelling...",
        
            cancellation_success: "Your transaction has been successfully canceled. You can check your transaction history or contact support if needed.",
            go_home_button: "Back to Home",
        
            error_fill_pin: "Please fill in all fields with numbers.",
            error_invalid_pin: "Your PIN code is incorrect.",
            error_unknown: "Unknown error. Please try again."
        },

        pageNotifications: { 
            title: 'Notifications',
        },
        CreatePin: { 
            title: 'Create your code PIN',
        },

        exchange: {
            title: "Currency Exchange",
            titleChangeSell: "SELLING",
            titleChangeBuy: "BUYING",
            balance: "Balance",
            transactionSuccess: "Exchange Successful",
            exchangedAmount: "Converted Amount",
            confirm: "Confirm",
            conversionInProgress: "Conversion in Progress",
            loading: "Loading",
            insufficientFunds: "Insufficient Funds.",
            invalidAmount: "Please enter a valid amount.",
            errorOccurred: "An error occurred, please try again later.",
            modalMessage: "Your currency exchange has been successfully completed!",
            insufficientChange: "The minimum amount for exchange is 5 USD.",
            ok: "OK",
            htgUsd: "HTG/USD",
            usdHtg: "USD/HTG"
        },
        
        
    },

    fr: { 
        pageLogin: {
            pageTitle: 'Se connecter',
            phoneOrEmail: 'Numéro de téléphone ou adresse email',
            password: 'Mot de passe',
            stayConnected: 'Restez connecté',
            forgotPassword: 'Mot de passe oublié ?',
            connecting: 'Connexion en cours...',
            connect: 'Se connecter',
            createAccount: 'Kreye yon kont',
            noAccount: 'Vous n\'avez pas de compte ?',
            noAccountFound: 'Aucun compte associé à ce nom numéro de téléphone ou email.',
            invalidPassword: 'Le mot de passe est incorrect.',
            accountInactive: 'Votre compte n\'est pas encore activé. Veuillez vérifier votre email pour l\'activer.',
            enterPhone: 'Veuillez entrer votre No de téléphone ou email.',
            enterValidPhone: 'Veuillez entrer un email valide ou un numéro de téléphone valide.',
            enterPassword: 'Veuillez entrer votre mot de passe.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Mot de passe oublié',
            phoneOrEmail: 'Numéro de téléphone ou adresse email',
            loading: 'En cours de chargement...',
            reset: 'Réinitialiser',
            accueil: 'Accueil',
            linkSent: 'Un lien de réinitialisation a été envoyé. Veuillez consulter votre email.',
            errorRegistered: 'Une erreur est survenue pendant la demande de réinitialisation.',
        },

        message: { 
            hello: 'Bonjour le monde',
            stayConnected: 'Rester connecté',
            fullName: 'Nom Complet',
            email: 'Email',
            phoneNumber: 'No de Téléphone',
            address: 'Adresse',
            dateOfBirth: 'Date de Naissance',
            gender: 'Genre',
            personalInfo: 'Informations Personnelles',
            securityPrivacy: 'Sécurité et Confidentialité',
            preferences: 'Préférences',
            activityHistory: 'Historique des Activités',
            accountManagement: 'Gestion de Compte',
            supportAssistance: 'Support et Assistance',
        },
        pageTitle: {
            profile: 'Mon Profil',
        },

        generalContent:{
            validateContactError_1: 'Le champ Numéro de téléphone ou Email est requis.',
            validateContactError_2: 'Veuillez entrer un email valide ou un numéro de téléphone valide.',
            passwordPattern: 'Le mot de passe doit comporter au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un symbole spécial.',
        },

        pageBasicTransfert: {
            pageTitle: 'Effectuer un Transfert',
            phoneOrEmail2: 'Téléphone ou email du bénéficiaire',
            phoneemail3: 'Entrez l’email ou le numéro de téléphone',
            emptyfnb: 'Le champ Numéro de téléphone ou Email est obligatoire.',
            wrongnuberemail: 'Veuillez entrer un email ou numéro de téléphone valide.',
            devise: 'Choix de la devise',
            Mount: 'Montant',
            AmountInput: 'Entrez le montant',
            withoutmount: 'Veuillez vérifier le montant',
            Boutton: 'Soumettre',
            subboutton: 'Traitement en cours...',
            wrongaccount: 'Aucun compte associé trouvé.',
            Insufficient_funds: 'Votre solde est insuffisant pour le montant et les frais de transfert.',
            no_pin: 'Pour des raisons de sécurité, vous devez d\'abord créer une clé d\'authentification avant de pouvoir effectuer des transactions.<br/><br/>Cette clé servira de second facteur d\'authentification pour valider vos opérations financières.<br/><br/>Veuillez configurer votre clé d\'authentification dans votre profil pour continuer.',
            create_pin: 'Configurer',
            name: 'Bénéficiaire',
            deviseT: 'Devise',
            fees: 'Frais de Transaction',
            mountT: 'Montant Total',
            pin: 'Entrez votre PIN',
            Boutton2: 'Valider',
            subboutton2: 'Transfert en cours...',
            errorpin: 'PIN incorrect.',
            pageTitle3: 'Transfert Réussi',
            success: 'Votre transaction a été effectuée avec succès.',
            Boutton3: 'Accueil',
            infoMissed: 'Veuillez remplir tous les champs obligatoires.',
            inactifAccount: 'Le compte est inactif.',
            sameAccount: 'Vous ne pouvez pas transférer vers le même compte.',
            unknownError: 'Erreur inconnue. Veuillez réessayer.',
            pinALpha: 'Veuillez remplir tous les champs uniquement avec des chiffres.'
        },

        pageBasicWithdraw: {
            title: "Retrait de fonds",
            agentTitle: "Chez un agent",
            bankTitle: "Vers une banque",
            
            phoneEmailAgent: "Téléphone ou email de l'Agent",
            enterPhoneEmail: "Entrez l'email ou le numéro de téléphone",
            currencyChoice: "Choix de la Devise",
            HTG: "Gourde (HTG)",
            USD: "Dollar (USD)",
            amount: "Montant",
            enterAmount: "Entrez le montant",
            submit: "Soumettre",
        
            bankChoice: "Choix de la Banque",
            accountNumber: "No de compte",
            enterAccountNumber: "Entrez le numéro de compte",
            accountName: "Nom du compte",
            enterAccountName: "Entrez le nom du compte",
        
            securityMessage: "Pour des raisons de sécurité, vous devez d'abord créer une clé d'authentification avant de pouvoir effectuer des transactions.",
            securityMessage2: "Cette clé servira de second facteur d'authentification pour valider vos opérations financières.",
            securityMessage3: "Veuillez configurer votre clé d'authentification dans votre profil pour continuer.",
            configure: "Configurer",
        
            agent: "Agent :",
            bank: "Banque :",
            currency: "Devise :",
            transactionFee: "Frais de Transaction :",
            totalAmount: "Montant Total :",
        
            insertPin: "Insérez votre PIN",
            processing: "Retrait en cours",
            validate: "Valider",
            
            withdrawSuccess: "Votre retrait a été effectué avec succès.",
            accueil: "Accueil",
        
            withdrawProcessing: "Votre demande de retrait est en cours de traitement.",
            withdrawPending: "Le fournisseur doit encore valider la transaction.",
            followTransaction: "Vous pouvez suivre l’évolution ou annuler la demande à tout moment en consultant votre ",
            followTransaction2: " ou vos",
            transactionHistoric: "historique de transactions",
            notifications: "notifications",
        
            required_fields: "Veuillez remplir tous les champs obligatoires.",
            no_account_found: "Aucun compte associé trouvé.",
            inactive_account: "Le compte est inactif.",
            same_account: "Vous ne pouvez pas transférer vers le même compte.",
            insufficient_funds: "Votre balance est insuffisante pour couvrir le montant et les frais de transfert.",
            unknown: "Erreur inconnue. Veuillez réessayer.",
            check_amount: "Veuillez vérifier votre montant",
            server_communication: "Erreur lors de la communication avec le serveur.",
        
            pin_numeric: "Veuillez remplir tous les champs avec des chiffres.",
            invalid_pin: "Votre code PIN est incorrect.",
        
            account_name_required: "Veuillez entrer un nom de compte.",
            account_name_min_length: "Le nom de compte doit comporter au moins 3 caractères.",
            account_name_max_length: "Le nom de compte ne doit pas dépasser 30 caractères.",
            account_name_invalid: "Le nom de compte ne peut contenir que des lettres, des accents, des espaces, des tirets, des points et des underscores.",
            
            account_number_required: "Veuillez entrer un numéro de compte.",
            account_number_length: "Le numéro de compte doit comporter exactement 10 chiffres.",
            account_number_invalid: "Le numéro de compte ne peut contenir que des chiffres, des tirets et des espaces."
        },

        pageConfirmWithdraw: {
            title: "Confirmation de Retrait",
            client: "Client :",
            amount: "Montant :",
            currency: "Devise :",
            transaction_fee: "Frais de Transaction :",
            total_amount: "Montant Total :",
            commission: "Commision :",
            insert_pin: "Insérer votre pin",
            error_invalid: "Votre code PIN est incorrecte",
            error_unknown: "Erreur inconnue. Veuillez réessayer.",
            error_fill_pin: "Veuillez remplir tous les champs avec des chiffres.",
            withdraw_success: "Vous avez confirmé le retrait avec succès.",
            home: "Accueil",
            withdraw_in_progress: "Confirmation en cours",
            validate: "Valider"
        },

        pageBasicDeposit:{
            pageTitle: 'Déposer de l\'Argent sur Votre Compte',
            account: "Téléphone ou Email de l'Agent",
            devise: "Choisir une Devise",
            amount: "Montant",
            underAmount: "Entrez le montant",
            underAccount: "Entrez l'email ou le numéro de téléphone de l'agent",
            nameHTG: "Gourde",
            nameUSD: "Dollar",
            button: "Soumettre",
            notPutAmount: "Veuillez vérifier le montant",
            tooMuchAmount: "Cet agent ne peut pas traiter ce dépôt pour vous",
            unknownNumberOrMail: "Aucun compte associé trouvé",
            inactifAccount: 'Le compte est inactif.',
            unknownError: 'Erreur inconnue. Veuillez réessayer.',
            sameAccount: 'Vous ne pouvez pas transférer vers le même compte.',
            infoMissed: 'Veuillez remplir tous les champs obligatoires.',

            noRecordPin: 'Pour des raisons de sécurité, vous devez d\'abord créer une clé d\'authentification avant de pouvoir effectuer des transactions.<br/><br/>Cette clé servira de second facteur d\'authentification pour valider vos opérations financières.<br/><br/>Veuillez configurer votre clé d\'authentification dans votre profil pour continuer.',
            configure: 'Configurer',

            name: "Agent",
            deviseT: "Devise",
            fees: "Frais de Transaction",
            mountT: "Montant Total",
            enterPin: "Entrez Votre PIN",
            button2: "Valider",
            subBoutton2: "transaction en cours...",
            badPin: 'Veuillez remplir tous les champs avec des chiffres.',
            errorPin: "Votre PIN est incorrect",

            successDeposit: 'Votre dépôt  a été effectué avec succès.',
            Button3: 'Accueil',
            waitingText: 'Votre demande de dépôt est en cours de traitement.\nLe fournisseur doit encore valider la transaction.\n\nVous pouvez suivre l’évolution ou annuler la demande à tout moment en consultant votre <a href="/transaction-history">historique de transactions</a> ou vos <a href="/notifications">notifications</a>.',

        },
        pageConfirmDeposit:{
            title: 'Validation de Dépôt',
        },

        pageTracking: {
            title: "Suivi de transaction",
            type: "Type",
            type_deposit: "Dépôt",
            type_withdrawal: "Retrait",
            type_transfer: "Transfert",
            type_default: "Type inconnu",
            status_pending: "En attente",
            status_completed: "Succès",
            status_failed: "Échoué",
            status_default: "Statut inconnu",
            agent: "Agent",
            bank: "Banque",
            account_number: "Numéro de compte",
            account_name: "Nom du compte",
            amount: "Montant",
            currency: "Devise",
            transaction_fee: "Frais de transaction",
            total_amount: "Montant total",
        
            cancel_button: "Annuler",
            pin_insert_title: "Entrez votre code PIN",
            cancel_button_submit: "Confirmer l'annulation",
            cancel_button_loading: "Annulation en cours...",
        
            cancellation_success: "Votre transaction a été annulée avec succès. Consultez votre historique de transactions ou contactez le support si nécessaire.",
            go_home_button: "Retour à l'accueil",
        
            error_fill_pin: "Veuillez remplir tous les champs avec des chiffres.",
            error_invalid_pin: "Votre code PIN est incorrect.",
            error_unknown: "Erreur inconnue. Veuillez réessayer."
        },

        pageNotifications: { 
            title: 'Notifications',
        },
        CreatePin: { 
            title: 'Créer votre code PIN',
        },

        exchange: {
            title: "Change de devises",
            titleChangeSell: "VENTE",
            titleChangeBuy: "ACHAT",
            balance: "Solde",
            transactionSuccess: "Conversion réussie",
            exchangedAmount: "Montant converti",
            confirm: "Confirmer",
            conversionInProgress: "Conversion en cours",
            loading: "Chargement",
            insufficientFunds: "Fonds insuffisants.",
            invalidAmount: "Veuillez entrer un montant valide.",
            errorOccurred: "Une erreur est survenue, veuillez réessayer plus tard.",
            modalMessage: "Votre conversion de devises a été effectuée avec succès !",
            insufficientChange: "Le montant minimum pour un change est de 5 USD.",
            ok: "OK",
            htgUsd: "HTG/USD",
            usdHtg: "USD/HTG"
        },
        
    },

    ht: {
        pageLogin: {
            pageTitle: 'Antre nan kont ou',
            phoneOrEmail: 'Nimewo telefòn oswa adrès imèl',
            password: 'Modpas',
            stayConnected: 'Rete konekte',
            forgotPassword: 'Ou bliye modpas ou?',
            connecting: 'Tanpri tann, ap konekte...',
            connect: 'Konekte w',
            createAccount: 'Kreye yon kont',
            noAccount: 'Ou poko gen kont?',
            noAccountFound: 'Pa gen kont ki koresponn ak non itilizatè oswa imèl sa a.',
            invalidPassword: 'Modpas sa a pa kòrèk.',
            accountInactive: 'Kont ou poko aktive. Tanpri tcheke imèl ou pou aktive l.',
            enterPhone: 'Tanpri antre nimewo telefòn ou oswa imèl ou.',
            enterValidPhone: 'Tanpri antre yon imèl oswa yon nimewo telefòn ki valab.',
            enterPassword: 'Tanpri antre modpas ou.',
        },
        pageRecoveryPassword: {
            pageTitle: 'Bliye modpas',
            phoneOrEmail: 'Nimewo telefòn oswa adrès imèl',
            loading: 'Chajman ap fèt...',
            reset: 'Reyajiste',
            accueil: 'Paj dakèy',
            linkSent: 'Yon lyen pou reyajiste modpas al jwenn ou. Tanpri verifye imèl ou.',
            errorRegistered: 'Yon erè rive pandan demann reyajisteman an.',
        },

        message: { 
            fullName: 'Non Konplè',
            email: 'Imèl',
            phoneNumber: 'Nimewo Telefòn',
            address: 'Adrès',
            dateOfBirth: 'Dat Nesans',
            gender: 'Sèks',
            personalInfo: 'Enfòmasyon Pèsonèl',
            securityPrivacy: 'Sekirite ak Konfidansyalite',
            preferences: 'Preferans',
            activityHistory: 'Istwa Aktivite',
            accountManagement: 'Jesyon Kont',
            supportAssistance: 'Sipò ak Asistans',
        },
        pageTitle: {
            profile: 'Pwofil Mwen',
        },
        generalContent:{
            validateContactError_1: 'Nimewo telefòn oswa imèl la obligatwa.',
            validateContactError_2: 'Tanpri antre yon imèl ki valab oswa yon nimewo telefòn ki valab.',
            passwordPattern: 'Modpas la dwe gen omwen 8 karaktè, yon lèt majiskil, yon lèt miniskil, yon chif ak yon senbòl espesyal.',
        },
        pageBasicTransfert: {
            pageTitle: 'Fè yon Transfè',
            phoneOrEmail2: 'Nimewo telefòn oswa imel benefisyè a',
            phoneemail3: 'Antre imel oswa nimewo telefòn',
            emptyfnb: 'Nimewo telefòn oswa imel obligatwa.',
            wrongnuberemail: 'Tanpri antre yon imel oswa nimewo telefòn ki valab.',
            devise: 'Chwazi lajan',
            Mount: 'Kantite lajan',
            AmountInput: 'Antre kantite kòb la',
            withoutmount: 'Tanpri tcheke kantite lajan ou',
            Boutton: 'Voye',
            subboutton: 'Pwosesis ap kontinye...',
            wrongaccount: 'Pa gen okenn kont asosye jwenn.',
            Insufficient_funds: 'Balans ou pa ase pou kantite lajan an ak frè transfè a.',
            no_pin: 'Pou rezon sekirite, ou dwe kreye yon kle otantifikasyon anvan ou kapab fè tranzaksyon.<br/><br/> Kle sa a ap sèvi kòm yon dezyèm faktè otantifikasyon pou valide operasyon finansye ou yo.<br/><br/> Tanpri konfiguré kle otantifikasyon ou nan pwofil ou pou kontinye.',
            create_pin: 'Konfigire',
            name: 'Benefisyè',
            deviseT: 'Lajan',
            fees: 'Frè Tranzaksyon',
            mountT: 'Montan Total',
            pin: 'Antre PIN ou',
            Boutton2: 'Konfime',
            subboutton2: 'Transfè ap fèt...',
            errorpin: 'PIN ou a pa kòrèk."',
            pageTitle3: 'Transfè Reyisi',
            success: 'Tranzaksyon ou reyisi.',
            Boutton3: 'Akèy',
            infoMissed: 'Tanpri ranpli tout chan obligatwa yo.',
            inactifAccount: 'Kont lan inaktif.',
            sameAccount: 'Ou pa ka transfere nan menm kont lan.',
            unknownError: 'Gen on erè nou pa idantifye. Tanpri eseye ankò.',
            pinALpha: 'Tanpri ranpli tout chan yo sèlman ak chif.'
        },

        pageBasicWithdraw: {
            title: "Retire Lajan",
            agentTitle: "Nan men yon Ajan",
            bankTitle: "Voye sou yon Bank",
        
            phoneEmailAgent: "Telefòn oswa imèl Ajan an",
            enterPhoneEmail: "Antre imèl oswa nimewo telefòn lan",
            currencyChoice: "Chwazi Lajan",
            HTG: "Goud (HTG)",
            USD: "Dola Ameriken (USD)",
            amount: "Kantite Lajan",
            enterAmount: "Antre kantite lajan an",
            submit: "Voye",
        
            bankChoice: "Chwazi Bank",
            accountNumber: "Nimewo Kont",
            enterAccountNumber: "Antre nimewo kont lan",
            accountName: "Non Kont",
            enterAccountName: "Antre non kont lan",
        
            securityMessage: "Pou sekirite ou, ou dwe kreye yon kle otantifikasyon anvan ou fè tranzaksyon.",
            securityMessage2: "Kle sa a ap sèvi kòm dezyèm faktè otantifikasyon pou valide operasyon finansye ou yo.",
            securityMessage3: "Tanpri mete kanpe kle otantifikasyon ou nan pwofil ou pou kontinye.",
            configure: "Konfigire",
        
            agent: "Ajan :",
            bank: "Bank :",
            currency: "Lajan :",
            transactionFee: "Frè Tranzaksyon :",
            totalAmount: "Total :",
        
            insertPin: "Antre PIN ou",
            processing: "Retire an pwosesis",
            validate: "Konfime",
        
            withdrawSuccess: "Retret ou a te reyisi avèk siksè.",
            accueil: "Akèy",
        
            withdrawProcessing: "Demann retret ou an ap trete.",
            withdrawPending: "Fournisè a dwe verifye tranzaksyon an anvan li ka fèt.",
            followTransaction: "Ou ka swiv pwogrè a oswa anile demann lan nenpòt lè nan ",
            followTransaction2: " oswa nan",
            transactionHistoric: "istwa tranzaksyon",
            notifications: "notifikasyon",
        
            required_fields: "Tanpri ranpli tout chan obligatwa.",
            no_account_found: "Pèsonn kont asosye pa jwenn.",
            inactive_account: "Kont lan inaktif.",
            same_account: "Ou pa ka transfere lajan nan menm kont lan.",
            insufficient_funds: "Balans ou pa ase pou kouvri kantite lajan an ak frè transfè a.",
            unknown: "Erè enkoni. Tanpri eseye ankò.",
            check_amount: "Tanpri verifye kantite lajan ou",
            server_communication: "Erè nan kominikasyon ak sèvè a.",
        
            pin_numeric: "Tanpri ranpli tout chan yo ak chif.",
            invalid_pin: "PIN ou a se pa bon.",
        
            account_name_required: "Tanpri antre yon non kont.",
            account_name_min_length: "Non kont lan dwe genyen omwen 3 karaktè.",
            account_name_max_length: "Non kont lan pa dwe depase 30 karaktè.",
            account_name_invalid: "Non kont lan ka sèlman genyen lèt, aksan, espas, tirè, pwen, ak tye sou liy.",
            
            account_number_required: "Tanpri antre yon nimewo kont.",
            account_number_length: "Nimewo kont lan dwe gen egzakteman 10 chif.",
            account_number_invalid: "Nimewo kont lan ka sèlman genyen chif, tirè ak espas."
        },

        pageConfirmWithdraw: {
            title: "Konfimasyon Retrè",
            client: "Kliyan :",
            amount: "Montan :",
            currency: "La Jan :",
            transaction_fee: "Frè Tranzaksyon :",
            total_amount: "Montan Total :",
            commission: "Komisyon :",
            insert_pin: "Mete PIN ou",
            error_invalid: "Kòd PIN ou a pa kòrèk",
            error_unknown: "Erè enkoni. Tanpri eseye ankò.",
            error_fill_pin: "Tanpri ranpli tout chan yo ak chif.",
            withdraw_success: "Ou te konfime retrè a avèk siksè.",
            home: "Akèy",
            withdraw_in_progress: "Konfimasyon an ap fèt",
            validate: "Valide"
        },

        pageBasicDeposit:{
            pageTitle: 'Mete Lajan sou Kont Ou',
            account: "Telefòn oswa Imèl Ajan",
            devise: "Chwazi ki Lajan",
            amount: "Kantite Lajan",
            underAmount: "Antre kantite lajan an",
            underAccount: "Antre imèl oswa nimewo telefòn ajan an",
            nameHTG: "Goud",
            nameUSD: "Dola",
            button: "Soumèt",
            notPutAmount: "Tanpri verifye kantite lajan an",
            tooMuchAmount: "Ajan sa a pa ka fè depo sa a pou ou",
            unknownNumberOrMail: "Pa jwenn okenn kont ki asosye",
            inactifAccount: 'Kont lan inaktif.',
            unknownError: 'Gen on erè nou pa idantifye. Tanpri eseye ankò.',
            sameAccount: 'Ou pa ka transfere nan menm kont lan.',
            infoMissed: 'Tanpri ranpli tout chan obligatwa yo.',

            noRecordPin: 'Pou rezon sekirite, ou dwe premye kreye yon kle otantifikasyon anvan ou ka fè tranzaksyon.<br/><br/> Kle sa a ap sèvi kòm yon dezyèm faktè otantifikasyon pou valide operasyon finansye ou yo.<br/><br/> Tanpri configuré kle otantifikasyon ou nan pwofil ou pou kontinye.',
            configure: 'Konfigire',

            name: "Ajan",
            deviseT: "Lajan",
            fees: "Frè Tranzaksyon",
            mountT: "Montan Total",
            enterPin: "Antre PIN ou",
            button2: "Konfime",
            subBoutton2: "Tranzaksyon an ap fèt...",
            badPin: 'Tanpri ranpli tout chan yo ak chif.',
            errorPin: "PIN ou pa kòrèk",

            successDeposit: 'Depo ou an te fèt avèk siksè.',
            Button3: 'Akèy',
            waitingText: 'Demann depo w la ap trete.\n\nFounisè a dwe toujou valide tranzaksyon an.\n\nOu ka swiv pwogrè a oswa anile demann lan nenpòt ki lè lè w konsilte <a href="/transaction-history">istwa tranzaksyon</a> ou oswa <a href="/notifications">notifikasyon</a> ou.',

        },
        pageConfirmDeposit:{
            title: 'Konfime Depo',
        },
        pageNotifications: { 
            title: 'Notifikasyon yo',
        },
        pageTracking: {
            title: "Suivi Tranzaksyon",
            type: "Kalite",
            type_deposit: "Depo",
            type_withdrawal: "Retrè",
            type_transfer: "Transfè",
            type_default: "Kalite enkoni",
            status_pending: "An atant",
            status_completed: "Siksè",
            status_failed: "Echwe",
            status_default: "Estati enkoni",
            agent: "Ajan",
            bank: "Bank",
            account_number: "Nimewo Kont",
            account_name: "Non Kont",
            amount: "Kantite",
            currency: "Lajan",
            transaction_fee: "Frè Tranzaksyon",
            total_amount: "Total",
        
            cancel_button: "Anile",
            pin_insert_title: "Antre PIN ou",
            cancel_button_submit: "Konfime Anilasyon",
            cancel_button_loading: "Anilasyon an ap fèt...",
        
            cancellation_success: "Tranzaksyon ou an anile avèk siksè. Ou ka tcheke istwa tranzaksyon ou oswa kontakte sipò si sa nesesè.",
            go_home_button: "Retounen deyò",
        
            error_fill_pin: "Tanpri ranpli tout chan yo ak chif.",
            error_invalid_pin: "PIN ou an pa kòrèk.",
            error_unknown: "Erè enkoni. Tanpri eseye ankò."
        },
        
        CreatePin: { 
            title: 'Kreye Kòd PIN ou',
        },

        exchange: {
            title: "Chanje Lajan",
            titleChangeSell: "VANN",
            titleChangeBuy: "ACHTE",
            balance: "Sòld",
            transactionSuccess: "Konvèsyon Reyisi",
            exchangedAmount: "Kantite konvèti",
            confirm: "Konfime",
            conversionInProgress: "Konvèsyon an ap fèt",
            loading: "Y ap chaje",
            insufficientFunds: "Kòb ou pa ase.",
            invalidAmount: "Tanpri mete yon kantite ki valab.",
            errorOccurred: "Yon erè rive, tanpri eseye ankò pita.",
            modalMessage: "Chanje lajan ou an fin fèt ak siksè !",
            insufficientChange: "Kantite minimòm pou chanje an se 5 USD.",
            ok: "OK",
            htgUsd: "HTG/USD",
            usdHtg: "USD/HTG"
        },
        
    }
};


// Créer une instance i18n
const i18n = createI18n({
    locale: 'fr', // Langue par défaut
    messages,
});

export default i18n;
