<template>
    <Header />
    <PageHeader title="Admin Financial Transaction" :fontSize="'1.50rem'" :goBack="customGoBack" />

    <div class="admin-transactions">
      
      <div v-if="loading" class="loading">Chargement...</div>
      
      <div v-else-if="transaction">
        <div class="invoice-block">
    
            <!-- Détails du contact -->
            <div class="invoice-detail" v-if="!transaction.isBankTransaction">
                <label>Agent :</label>
                <span>{{ transaction.fullname || 'Nom indisponible' }} ({{ transaction.phone || 'No indisponible' }})</span>
            </div>

            <div class="invoice-detail" v-if="transaction.isBankTransaction">
                <label>Banque :</label>
                <span>{{ transaction.bank_name || 'Nom indisponible' }}</span>
            </div>
            <div class="invoice-detail" v-if="transaction.isBankTransaction">
                <label>No Compte :</label>
                <span>{{ transaction.account_number || 'Nom indisponible' }}</span>
            </div>
            <div class="invoice-detail" v-if="transaction.isBankTransaction">
                <label>Nom de compte :</label>
                <span>{{ transaction.account_name || 'Nom indisponible' }}</span>
            </div>

            <!-- Détails du montant -->
            <div class="invoice-detail">
            <label>Montant :</label>
            <span>
                {{ transaction.currency === 'HTG' ? formatCurrency(transaction.amount, 'HTG', 'fr-HT') : formatCurrency(transaction.amount, 'USD', 'en-US') }}
            </span>
            </div>

            <!-- Détails de la devise -->
            <div class="invoice-detail">
            <label>Devise :</label>
            <span>{{ transaction.currency }}</span>
            </div>

            <!-- Détails du montant total -->
            <div class="invoice-detail total">
            <label>Montant Total :</label>
            <span>{{ transaction.currency === 'HTG' ? formatCurrency(transaction.amount, 'HTG', 'fr-HT') : formatCurrency(transaction.amount, 'USD', 'en-US') }}</span>
            </div>

            <div v-if="transaction.status === 'pending'" class="transaction-actions">
                <div class="form-group">
                    <label for="adminCode">
                        Entrez votre code Admin
                    </label>
                    <input type="text" id="adminCode" v-model="adminCode" @input="codeInput" placeholder="Votre code Admin" />
                    <span v-if="codeError" class="error-message">{{ codeError }}</span>
                </div>

                <div class="buttons">
                    <button class="cancel" :disabled="loadingCancel">
                        <span v-if="loadingCancel">
                            Exécution en cours <i class="fas fa-spinner fa-spin"></i>
                        </span>
                        <span v-else @click="cancelTransaction">
                            <i class="fas fa-close"></i> Annuler
                        </span>
                    </button>
                    
                
                    <button class="confirm"  :disabled="loadingConfirm">
                        <span v-if="loadingConfirm">
                            Exécution en cours <i class="fas fa-spinner fa-spin"></i>
                        </span>
                        <span v-else @click="confirmTransaction">
                            <i class="fas fa-thumbs-up"></i> Confirmer
                        </span>
                    </button>

                </div>
            </div>


        </div>
      </div>

      <div class="block-a2f" v-else-if="activeIndex === 1">
        <img src="@/assets/images/confirmed-register.png" alt="confirmation enregistrement" class="mockup-image" />
        <p>Vous avez confirmé le retrait avec succès.</p>
        <button @click="goToHome" class="btn-submit">
            {{ $t('pageRecoveryPassword.accueil') }} <i class="fas fa-home"></i>
        </button>
      </div>

      <div class="block-a2f" v-else-if="activeIndex === 2">
        <img src="@/assets/images/confirmed-register.png" alt="confirmation enregistrement" class="mockup-image" />
        <p>Vous avez annulé le retrait avec succès.</p>
        <button @click="goToHome" class="btn-submit">
            {{ $t('pageRecoveryPassword.accueil') }} <i class="fas fa-home"></i>
        </button>
      </div>

      <div v-else class="box-error">
        <div class="error">
        ⚠️ Impossible de charger la transaction. ID invalide ou transaction introuvable.
      </div>
      </div>
  
      
    </div>

    <Footer :key="footerKey" />
</template>
  
<script>
    import Header from '../shared/HeaderPage.vue';
    import Footer from '../shared/FooterPage.vue';
    import PageHeader from '../shared/PageHeader.vue';

    import CryptoJS from "crypto-js";
    import axios from 'axios';
    import { SERVER_IP } from '../../config';
    import { formatCurrency } from '@/utils/validationUtils';

  export default {
    props: ["encryptedId"],
    components: {
      Header,
      PageHeader,
      Footer,
    },
    data() {
      return {
        transactionId: null, // ID réel après déchiffrement
        transaction: null, // Données de la transaction
        loading: true,
        adminCode: '',
        codeError: '',
        loadingConfirm: false,
        loadingCancel: false,
        activeIndex: 0,
        secretKey: "mon_secret_key_super_securise", // Remplace par ta clé secrète
      };
    },
    methods: {

        formatCurrency(amount, currency, locale) {
            return formatCurrency(amount, currency, locale);
        },

        codeInput(){
            this.codeError = '';
        },

        goToHome() {
            this.$router.push('/home');
        },

      decryptId(encryptedId) {
        try {
          const bytes = CryptoJS.AES.decrypt(encryptedId, this.secretKey);
          const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
          if (!decryptedId) throw new Error("Déchiffrement échoué");
          return decryptedId;
        } catch (error) {
          console.error("Erreur lors du déchiffrement de l'ID :", error);
          return null;
        }
      },

      getErrorMessage(errorCode) {
          switch (errorCode) {
              case 'access_denied':
                  return 'Vous n\'avez pas accès pour cette opération.';
              case 'insufficient_funds':
                  return 'La balance du compte est insuffisante pour le montant plus les frais de transfert.';
              default:
                  return 'Erreur inconnue. Veuillez réessayer.';
          }
      },

      scrollToError(field) {
            const element = document.getElementById(field);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            this.loadingConfirm = false;
            this.loadingCancel = false;
        },
  
      async fetchTransaction() {
        const url = `${SERVER_IP}/admin-transactions/get-transaction`;
        try {
            // Appel API pour récupérer la balance
            const response = await axios.post(url, {
                transactionId: this.encryptedId
            });

            if (response.status === 200) {
                this.transaction = response.data.transaction;
            }
        }  catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
          this.transaction = null;
        } finally {
          this.loading = false;
        }
      },

      async confirmTransaction() {
        if(this.loadingConfirm || this.loadingCancel) {
            return;
        }
        this.loadingConfirm = true;

        if(this.adminCode.trim() === '') {
            this.codeError = 'Vous devez entrer votre code';
            this.loadingConfirm = false;
            return;
        }
        const url = `${SERVER_IP}/admin-transactions/confirm-withdraw`;
        try {
            // Appel API pour récupérer la balance
            const response = await axios.post(url, {
                transactionId: this.encryptedId,
                code: this.adminCode
            });

            if (response.status === 200) {
                this.transaction = null;
                this.activeIndex = 1;
            }
        }  catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
          this.codeError = 'Une erreur est survenue dans le systeme';
          if (error.response){
              this.codeError = this.getErrorMessage(error.response.data.error);
              this.scrollToError('codeError');
          }
        } finally {
          this.loadingConfirm = false;
        }

      },

      async cancelTransaction() {
        if(this.loadingConfirm || this.loadingCancel) {
            return;
        }
        this.loadingCancel = true;

        if(this.adminCode.trim() === '') {
            this.codeError = 'Vous devez entrer votre code';
            this.loadingCancel = false;
            return;
        }
        const url = `${SERVER_IP}/admin-transactions/cancel-withdraw`;
        try {
            // Appel API pour récupérer la balance
            const response = await axios.post(url, {
                transactionId: this.encryptedId,
                code: this.adminCode
            });

            if (response.status === 200) {
                this.transaction = null;
                this.activeIndex = 2;
            }
        }  catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
          this.codeError = 'Une erreur est survenue dans le systeme';
          if (error.response){
              this.codeError = this.getErrorMessage(error.response.data.error);
              this.scrollToError('codeError');
          }
        } finally {
          this.loadingConfirm = false;
        }

      },
    },
    mounted() {
      if (this.encryptedId) {
        // this.transactionId = this.decryptId(this.encryptedId);
        if (this.encryptedId) {
          this.fetchTransaction();
        } else {
          this.loading = false;
        }
      } else {
        this.$router.go(-1);
      }
    },
  };
</script>

  
<style scoped>
  .admin-transactions {
    max-width: 400px;
    margin: auto;
    padding: 0px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5.5%;
  }

  .box-error {
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 35%;
  }
  
  .invoice-block{
    max-width: 400px;
    margin: auto;
    padding: 0px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5.5%;
    margin-top: 20%;
    }

    .invoice-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    }

    .invoice-detail.total {
    font-weight: bold;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    }
  
  .loading {
    text-align: center;
    font-size: 18px;
    color: #007bff;
  }
  
  .error {
    color: red;
    text-align: center;
    font-size: 16px;
  }

  .transaction-actions {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}



/* 📌 Boutons */
.buttons {
  display: flex;
  justify-content: space-between;
}

.buttons button {
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 15px;
}

.cancel {
  background-color: #e9e8e8;
  color: #000;
}

.confirm {
    background-color: #016a40;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 15px;
}
.confirm:hover {
    background-color: #005934;
}

.form-group label {
  display: block; /* Afficher les labels en bloc */
}

label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

input {
  padding: 0.5em 0;
  font-size: 1em;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

input:focus{
  outline: none;
  border-bottom: 2px solid #016A40; /* Couleur de focus verte */
}
.error-message {
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #FFFC00; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
}

.block-a2f{
  max-width: 400px;
  margin: auto;
  padding: 0px;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.block-a2f p{
  font-size: 1rem;
}
.block-a2f p a{
    color: #03a5fc;
    text-decoration: none;
}
.block-a2f p a:hover{
    color: #08489c;
}

.mockup-image {
  max-width: 120px;
  margin: 20px auto;
  display: block;
}
.btn-submit {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 15px;
}

.btn-submit:hover {
  background-color: #005934;
}

</style>
  