export function bankName(indic) {
    const bankMap = {
        'Sogebank': 'Sogebank',
        'Unibank': 'Unibank',
        'BNC': 'Banque Nationale de Crédit',
        'BUH': 'Banque de l\'Union Haïtienne',
        'Capital Bank': 'Capital Bank',
        'BPH': 'Banque Populaire Haïtienne',
        'BRH': 'Banque de la République d\'Haïti'
    };
    
    return bankMap[indic] || 'Nom inconnue'; // Gestion d'un cas par défaut
}
