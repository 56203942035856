<template>
  <Header />
  <PageHeader :title="$t('exchange.title')" :fontSize="'1.70rem'" :goBack="customGoBack" />

  <div class="exchange-container">

      <div class="exchange-ui">       
         <!-- Top Rectangle (HTG) -->
          
          <div class="currency-box top-box">

            <div class="title-change">
              {{ titleChange }}
            </div>
            
            <div class="currency-content"> 
              <div class="currency-details">
                  <h1>{{ currentCurrency1 }}</h1>
                  <p v-if="!dataBalance" class="loading-spinner">
                    <i class="fas fa-spinner fa-spin"></i> <!-- Icône de chargement -->
                  </p>
                  <p v-else class="balance">{{ $t('exchange.balance') }} : {{ getBalance(currentCurrency1) }}</p>
              </div>
              <div class="input-box">
                  <input
                    ref="htgInput"
                    type="number"
                    v-model="inputAmount"
                    placeholder="0.00"
                    @input="updateSoldAmount"
                  />
              </div>
            </div>
              
          </div>
          <!-- Intersection Indicator -->
          <div class="exchange-indicator">
              <div class="swap-icon" @click="permuteChange">
                  <i class="fas fa-sync"></i>
              </div>
              <div class="exchange-rate">
                  <p v-if="displayedRate && rateConstant">
                    {{ displayedRate }} {{ rateConstant }}
                  </p>
                  <p v-else>
                    <i class="fas fa-spinner fa-spin"></i> {{ $t('exchange.loading') }}...
                  </p>
                </div>
          </div>

          <!-- Bottom Rectangle (USD) -->
          <div class="currency-box bottom-box">
            <div class="currency-content">
              <div class="currency-details">
                  <h1>{{ currentCurrency2 }}</h1>
                  <p v-if="!dataBalance" class="loading-spinner">
                    <i class="fas fa-spinner fa-spin"></i> <!-- Icône de chargement -->
                  </p>
                  <p v-else class="balance">{{ $t('exchange.balance') }} : {{ getBalance(currentCurrency2) }}</p>
              </div>

              <div class="input-box">
                <label>{{ soldAmount }}</label>
              </div>
            </div>
            <div class="action-box">              
              <button type="submit" class="confirm-btn" :disabled="loading">
                <span v-if="loading">
                  {{ $t('exchange.conversionInProgress') }} <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else @click="confirmExchange">
                  <i class="fas fa-circle-check"></i> {{ $t('exchange.confirm') }}
                </span>
              </button>
              
              <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
              </div>
            </div>
          </div>

      </div>

    <!-- Historical Rates -->
    <!-- <section class="historical-rates">
      <h2>📊 Historique des taux</h2>
      <p>Taux de change récents (USD ⇔ HTG) :</p>
      <ul>
        <li v-for="(rate, index) in historicalRates" :key="index">
          <span>{{ rate.date }} :</span>
          <strong>1 USD = {{ rate.rate }} HTG</strong>
        </li>
      </ul>
    </section> -->

  </div>

  <Footer :key="footerKey" />



  <!-- Modal de confirmation -->
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <h2><i class="fas fa-circle-check icon-bg"></i> {{ $t('exchange.transactionSuccess') }}</h2>
        <button class="close-btn" @click="closeModal">×</button>
      </div>
      <div class="modal-body">
        <p>{{ $t('exchange.modalMessage') }}</p>
        <p>{{ $t('exchange.exchangedAmount') }} : <strong>{{ getSoldAmount() }}</strong></p>
      </div>
      <div class="modal-footer">
        <button @click="closeModal" class="btn-primary"><i class="fas fa-thumbs-up"></i> {{ $t('exchange.ok') }}</button>
      </div>
    </div>
  </div>


</template>

<script>
  import Header from '../shared/HeaderPage.vue';
  import Footer from '../shared/FooterPage.vue';
  import PageHeader from '../shared/PageHeader.vue';
  import axios from 'axios';
  import { SERVER_IP } from '../../config';

  import {  formatCurrency, checkAuth } from '@/utils/validationUtils';


export default {
  name: 'ExchangeView',

  components: {
      Header,
      PageHeader,
      Footer
  },
  created(){
      checkAuth();
  },
  mounted() {
    this.titleChange =  this.$t('exchange.titleChangeSell');
    // Appeler la méthode fetchBalance lorsque le composant est monté
    this.fetchBalance();

    // Donner le focus au champ de saisie dès que le composant est monté
    this.$nextTick(() => {
      this.$refs.htgInput.focus();
    });
  },
  
  data() {
    return {      
      historicalRates: [
        { date: "2025-01-24", rate: 140 },
        { date: "2025-01-23", rate: 139.5 },
        { date: "2025-01-22", rate: 140.2 },
      ],

      titleChange: 'VENTE',

      inputAmount: null, // Montant saisi en HTG
      soldAmount: 0, // Montant calculé en USD
      saveSoldAmount:0,
      errorMessage: "", // Message d'erreur

      dataBalance: null,
      currentCurrency1: 'HTG', // Devise affichée en haut
      currentCurrency2: 'USD', // Devise affichée en bas

      exchangeRate: null,
      displayedRate: 0, // La valeur affichée (taux actuel)
      rateConstant: "HTG/USD", // La constante affichée
      isSellRate: true, // Détermine si on est en mode "vente"

      loading: false,
      isVisible: false,

    };
  },
  methods: {

    async fetchBalance() {
      const userId= checkAuth();
      const url = `${SERVER_IP}/change-operation/getdata`;
      try {
        // Appel API pour récupérer la balance
        const response = await axios.post(url, {
            userId: userId
        });

        if (response.status === 200) {
          this.dataBalance = response.data.balance;
          this.exchangeRate = response.data.rates;
          this.displayedRate = this.exchangeRate.sell_rate; // Convertir en nombre
          this.rateConstant = "HTG/USD";
          
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de la balance :', error);
      }
    },

    // Récupère la balance d'une devise spécifique
    getBalance(currency) {
      const account = this.dataBalance?.find((item) => item.currency_code === currency);
      return account ? formatCurrency(account.balance, currency) : '0.00';
    },
    getCurrentBalance(currency) {
      const account = this.dataBalance?.find((item) => item.currency_code === currency);
      return account ? account.balance : '0.00';
    },

    formatCurrency(amount, currency){
      formatCurrency(amount, currency)
    },

    getSoldAmount(){
      return formatCurrency(this.saveSoldAmount, this.currentCurrency2);
    },

    customGoBack() {
        if(!this.loading) {
          this.$router.go(-1);
        }
    },

    permuteChange() {
      // Échange les devises
      let temp = this.currentCurrency1;
      this.currentCurrency1 = this.currentCurrency2;
      this.currentCurrency2 = temp;

      if (this.isSellRate) {
        this.displayedRate = this.exchangeRate.buy_rate;
        this.rateConstant = "USD/HTG";
        this.titleChange = this.$t('exchange.titleChangeBuy');
        this.inputAmount = this.soldAmount;
      } else {
        this.displayedRate = this.exchangeRate.sell_rate;
        this.rateConstant = "HTG/USD";
        this.titleChange = this.$t('exchange.titleChangeSell');
        this.soldAmount = this.inputAmount;
      }
      this.isSellRate = !this.isSellRate; // Alterne entre les états
      // this.inputAmount = this.soldAmount;

      if (!this.isSellRate) {
        this.soldAmount = this.inputAmount * this.displayedRate;
      } else {
        this.inputAmount = this.soldAmount * this.displayedRate;
      }
      // this.updateSoldAmount();

    },
  
    resetConversion() {
      this.fromAmount = null;
      this.convertedAmount = null;
    },

    updateSoldAmount() {
      if (this.inputAmount) {
        if (this.isSellRate) {
          this.soldAmount = (this.inputAmount / this.displayedRate).toFixed(2);
        }
        else {
          this.soldAmount = (this.inputAmount * this.displayedRate).toFixed(2);
        }
              
      } else {
            this.soldAmount = 0;
      }
      this.errorMessage = '';
    },

    async confirmExchange() {
        this.loading = true;
        if (this.inputAmount > this.getCurrentBalance(this.currentCurrency1)) {
            this.errorMessage = this.$t('exchange.insufficientFunds');
            this.loading = false;
            return;
        }
        
        else if (!this.inputAmount || this.inputAmount <= 0) {
            this.errorMessage = this.$t('exchange.invalidAmount');
            this.loading = false;
            return;
        } 
        
        const isUsdAndBelowMinInput = this.currentCurrency1 === 'USD' && this.inputAmount < 5;
        const isUsdAndBelowMinSold = this.currentCurrency2 === 'USD' && this.soldAmount < 5;

        if (isUsdAndBelowMinInput || isUsdAndBelowMinSold) {
            this.errorMessage = this.$t('exchange.insufficientChange');
            this.loading = false;
            return;
        }


        const userId= checkAuth();
        const url = `${SERVER_IP}/change-operation/changes`;
        try {
        // Appel API pour récupérer la balance
        const response = await axios.post(url, {
            userId: userId,
            input: this.inputAmount,
            sold: this.soldAmount,
            currency: this.currentCurrency1
        });

        if (response.status === 200) {
          this.inputAmount = 0;
          this.saveSoldAmount = this.soldAmount;
          this.soldAmount = 0;
          this.fetchBalance();
          this.isVisible = true;          
        }
      } catch (error) {
        this.errorMessage = this.$t('exchange.errorOccurred');
      } finally {
        this.loading = false;
      }

    },

    closeModal() {
      this.isVisible = false;
    },

  },
};
</script>

<style scoped>
.exchange-container {
  max-width: 400px;
  margin: auto;
  padding: 0px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5.5%;
}



.conversion-section {
  margin-bottom: 20px;
}

.conversion-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

.title-change {
  font-weight: bold;
  font-size: 1.5rem;
}

.historical-rates {
  margin-top: 20px;
  background: #f9f9f9;
}

.historical-rates h2 {
  font-size: 20px;
}

.historical-rates ul {
  list-style-type: none;
  padding: 0;
}

.historical-rates li {
  font-size: 14px;
  margin: 5px 0;
}
</style>

<style scoped>
.exchange-ui {
width: 100%;
border-radius: 30px;
margin-top: 20%;
}

.exchange-indicator {
position: relative;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 0px;
background: #e0e0e0;
}

.exchange-rate {
position: absolute;
background: #fff;
padding: 0px 15px;
border-radius: 50px;
border: 1px solid #e0e0e0;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
font-size: 0.8em;
font-weight: bold;
color: #333;
}

.swap-icon {
position: absolute;
left: 20px;
top: 50%;
transform: translateY(-50%);
background: #fff;
border-radius: 50%;
border: 1px solid #e0e0e0;
width: 30px;
height: 30px;
display: flex;
justify-content: center;
align-items: center;
font-size: 18px;
color: green;
cursor: pointer;
}

.currency-box {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 20px;
box-sizing: border-box;
}

.top-box {
background: #fff;
border-bottom: 1px solid #e0e0e0;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}

.bottom-box {
background: #f5f5f5;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
}

.currency-details{
  flex: 2;
  text-align: left;
}

.input-box { 
  flex: 1;
  text-align: right;
}

.currency-details h1 {
font-size: 24px;
font-weight: bold;
margin: 0;
}

.currency-details .balance {
font-size: 0.7rem;
color: #999;
}

.input-box label {
  font-size: 24px;
}
.input-box input {
font-size: 24px;
border: none;
background: none;
width: 100%;
outline: none;
text-align: right;
}

.currency-box {
display: flex;
flex-direction: column; /* Permet l'alignement vertical */
align-items: stretch; 
width: 100%;
padding: 20px;
box-sizing: border-box;
}

.bottom-box {
background: #f5f5f5;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
padding-top: 20px;
}

.currency-content {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px; /* Ajoute un espace entre les deux blocs */
padding-top: 5%;
}

.currency-details {
flex: 2;
text-align: left;
}

.input-box {
flex: 1;
text-align: right;
}

.action-box {
display: flex;
flex-direction: column; /* Pour empiler le bouton et les messages d'erreurs */
align-items: center; /* Centre horizontalement le contenu */
gap: 10px; /* Ajoute de l'espace entre le bouton et le message */
}

.confirm-btn {
background-color: #016A40; /* Fond vert */
color: white; /* Texte blanc */
border: none;
border-radius: 5px; /* Coins moins circulaires */
padding: 10px 20px; /* Ajustement du rembourrage */
cursor: pointer;
transition: background-color 0.3s; /* Transition pour l'animation */
width: auto;
margin-top: 5%;
}

.confirm-btn:hover {
background-color: #005934; /* Changement de couleur au survol */
}

.confirm-btn:active {
background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}

.error-message {
  text-align: center;
  font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
  font-weight: normal; /* Ajustez le poids de la police si nécessaire */
  transition: font-size 0.3s; /* Transition pour une animation douce */
  color: #8f1606; /* Couleur du texte rouge */
  font-style: italic; /* Texte en italique */
  margin-top: 5px;
}

</style>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 350px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body p {
  margin: 10px 0;
}

.modal-footer {
  margin-top: 20px;
}

.btn-primary {
background-color: #016A40; /* Fond vert */
color: white; /* Texte blanc */
border: none;
border-radius: 5px; /* Coins moins circulaires */
padding: 10px 20px; /* Ajustement du rembourrage */
cursor: pointer;
transition: background-color 0.3s; /* Transition pour l'animation */
width: auto;
margin-top: 5%;
}

.btn-primary:hover {
background-color: #005934; /* Changement de couleur au survol */
}

.btn-primary:active {
background-color: #003822; /* Changement de couleur lorsqu'il est cliqué */
}
</style>

